import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Html from "./Html";
import environment from "../../../environment";
import { useEffect } from "react";
const Sidebar = ({ isOpen }) => {
  const user = useSelector((state) => state.user);
  const history = useNavigate();
  const menus = {
    users: ["roles", "users"],
    sales: ["sales",'orders', "saleData", "sale/retrive","sales/refunds"],
    catalogue: ["types", "categories", "category/Reseller"],
    emailSetting:[
      "email",
    ],
    productdashboard: [
      "product-dashboard",
      "products",
      'product',
      '/cancel/policy',
      "product-data",
      '/content/settings',
      "product/detail",
      '/reseller/updates',
      "product/retrivable",
      'costingtour'
      
    ],
    socialMedia:[
      '/social/media'
    ],
    templates:[
      'gmail', "whatsapp/template", "whtasapp/template/add"
    ],
    whatsapp:["whatsapp/template","whtasapp/template/add"],
    customers: ["customers", "customer-data", 'waiver', 'customerdetails'],
    dynamicPricing: ["dynamicprice"],
    reviews: ["reviews", "review-dashboard"],
    plan: ["plans", "card"],
    customer: ["customer", 'customerdetails','waiver'],
    marketing: ["marketing", 'coupons','google'],
    financial: ["financial", "accounting"],
    googletag: ['/google/tags'],
    settings: [
      "two-factor-auth",
      'costing',
      'partner',
      '/email/addedit/',
      "settings",
      "company",
      "activeplan",
      '/omni-channel-setting/social/media',
      "plans",
      "card",
      '/email/templates',
      '/email/list',
      "users",
      "roles",
      "product",
      "pos",
      "api/",
      "marketing-analytics",
      "reviewStep",
      "roles",
      '/omni-channel-setting/google/tag',
      "users",
      "marketing-software","default-commission"
    ],
    omni: ["omni-channels", '/whatsapp/template', , '/omni-channel-setting/integration', '/chatbot','/omni-channel-setting/widget','gmail'],
    affiliates: ["request","affiliate","messages",'banner',"suppliers","trackData"],
    crm: ["reseller", "reseller-data", 'crm','/reseller-invoices'],
    contents: ['/reseller/updates','/content/settings'],
    api: [
      "product",
      "pos",
      "api/",
      "marketing-analytics",
      "reviewStep",
      "review-connected",
    ],
    templatescrm:['crm'],
    geo: ["continents", "countries", "regions", "cities"],
  };

  const ListItemLink = ({ to, type = "link", disabled = false, ...rest }) => {
    let url = location.href;
    const host = location.host;
    url = url.split(host)[1];
    return (
      <>
        {type == "link" ? (
          <li className={`nav-item ${url.includes(to) ? "active" : ""}`}>
            <Link
              to={to}
              {...rest}
              className={`nav-link hoverclass ${disabled ? "disabled" : ""}`}
              disabled={disabled}
            />
          </li>
        ) : (
          <li
            className={`nav-item main ${url.includes(to) ? "active" : ""}`}
            {...rest}></li>
        )}
      </>
    );
  };

  const matchUrl = (to, exact = false) => {
    let url = location.href;
    const host = location.host;
    url = url.split(host)[1];
    if (exact) return url == to;
    if (!exact) return url.includes(to);
  };

  const tabclass = (tab) => {
    let url = location.href;
    let value = false;
    menus[tab].map((itm) => {
      if (url.includes(itm)) value = true;
    });
    return value;
  };

  const urlAllow = (url) => {
    if(user?.subRole?.id==environment.SubRolePartner){
      return true
    }
    let permissions = user?.subRole && user?.subRole?.permissions && user?.subRole?.permissions[0];
    let arr = url?.split(",");
    let value = false;
    arr.map((itm) => {
      if (permissions?.[itm]) value = true;
    });
    if (user?.subRole?.id || user?.subRole?._id) {
      return value;
    } else {
      return true;
    }
  };

  const route = (p) => {
    history(p);
  };

  const scrollToId = (id) => {
    let data=id
    if (id.includes('/email')||id.includes('/emails')){
      data ='/email/list'
    }
    const element = document.getElementById(data);
    if (element) {
      element.scrollIntoView({ behavior: 'auto' });
    } else { 
    }
  };
  useEffect(() => {
    try{
      scrollToId(window.location.pathname == '/accounting/bills' ? "/accounting/invoices" : window.location.pathname.includes('google/tag') ?'/marketing/google-analytics':window.location.pathname)
  }catch{ 
  }
  }, [window.location.pathname]);
  return (
    <>
      <Html
        route={route}
        scrollToId={scrollToId}
        tabclass={tabclass}
        ListItemLink={ListItemLink}
        urlAllow={urlAllow}
        isOpen={isOpen}
      />
    </>
  );
};

export default Sidebar;
