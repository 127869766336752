import React from "react";
import methodModel from "../../../methods/methods";
import { Link } from "react-router-dom";
import "./style.scss";
import Layout from "../../global/layout";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import SelectDropdown from "../../common/SelectDropdown";
import GooglePlaceAutoComplete from "../../common/GooglePlaceAutoComplete";

const Html = ({
  handleSubmit,
  setForm,
  form,
  getError,
  uploadImage,
  submitted,
  channelsCategories,
  affiliateTypeCategories,
  platforms,
  handlePlatformUrlChange,
  visitors,
  setVisitor,
  addressResult
}) => {
  return (
    <Layout>
      <div className="max-w-[1020px]">
        <div className="flex justify-between items-center mb-6">
          <h4 className="text-2xl font-semibold text-[#111827]">Basic Information</h4>
          {/* <Link to="/profile" className="btn btn-primary ">
          <i className="fa fa-arrow-left text-light" title='View Profile' />
         
        </Link> */}
        </div>
        <div className="p-6 shadow-box border !border-grey rounded-large bg-white">
          <form onSubmit={handleSubmit} className="max-w-[913px]">
            <div className="flex items-center gap-4 mb-6">
              <label className="text-typo text-sm font-medium max-w-sm w-full block">Image</label>
              <div>
                <div className="maininutcls">
                  <label className="profileImageLabel">
                    <img
                      src={methodModel.userImg(form && form.image)}
                      className="w-16 h-16 min-w-[64px] overflow-hidden object-cover border border-gray-50 shadow-box border !border-grey rounded-full"
                    />
                  </label>
                  <div className="profile_btn">
                    <div>
                      <label className="mb-0 cursor-pointer !px-2 text-xs font-normal text-white h-9 flex items-center justify-center gap-2 !bg-primary rounded-extra_large shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed ml-3">
                        <input
                          id="bannerImage"
                          type="file"
                          className="hidden"
                          accept="image/*"
                          value={form.baseImg ? form.baseImg : ""}
                          onChange={(e) => {
                            uploadImage(e);
                          }}
                        />
                        {form.image ? "Change" : "Upload"} Image
                      </label>
                    </div>
                    <div>
                      {form.image ? (
                        <label
                          className="btn btn-primary  delete ml-3"
                          onClick={(e) => setForm({ ...form, image: "" })}>
                          Remove Image
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4 mb-6">
              <label className="text-typo text-sm font-medium max-w-sm w-full block">
                Name<span className="star">*</span>
              </label>
              <div className="w-full max-w-lg">
                <input
                  type="text"
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                  placeholder="Enter Name"
                  value={form.fullName ? form.fullName : ""}
                  onChange={(e) =>
                    setForm({ ...form, fullName: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-6">
              <label className="text-typo text-sm font-medium max-w-sm w-full block">Email</label>
              <div className="w-full max-w-lg">
                <input
                  type="email"
                  className="shadow-box border !border-grey bg-white w-full text-sm disabled:bg-gray-100 placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                  placeholder="Enter Email"
                  value={form.email ? form.email : ""}
                  disabled
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-6">
              <label className="text-typo text-sm font-medium max-w-sm w-full block">
                Mobile No<span className="star">*</span>
              </label>
              <div className="w-full max-w-lg">
                <div className="form-row">
                  <div className="col-md-12">
                    <PhoneInput
                      required
                      enableSearch={true}
                      autoFormat={false}
                      placeholder="Enter phone number"
                      value={form?.mobileNo}
                      countryCodeEditable={true}
                      onChange={(e) => setForm({ ...form, mobileNo: e })}
                      buttonClass="!border border-grey !roundehidden !bg-tranparent !rounded-l-large"
                      inputClass="shadow-box border !rounded-large !border-grey !min-h-10 text-sm placeholder:text-gray-500 flex items-center gap-2 overflow-hidden !px-2 !pl-12 !ring-primary !outline-primary"
                    />
                  </div>
                  {/* <div className="col-md-9">
                      <div className="phoneInput" className="w-full max-w-lg">
                        <input
                          type="text"
                          className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                          placeholder="Mobile No."
                          value={(form && form.mobileNo) || ""}
                          maxLength={12}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              mobileNo: methodModel.isNumber(e),
                            })
                          }
                          required
                        />
                      </div>
                    </div> */}
                </div>
                {submitted &&
                  getError("mobileNo").invalid &&
                  !getError("dialCode").invalid ? (
                  <div className="invalid-feedback d-block">
                    Min Length is 10
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4 mb-6">
              <label className="text-typo text-sm font-medium max-w-sm w-full block">Role</label>
              <div className="w-full max-w-lg">
                <input
                  type="text"
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex disabled:bg-gray-100 items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                  placeholder="Enter Role"
                  value={form?.role?.name}
                  disabled
                />
              </div>
            </div>
           <div className="flex items-center gap-4 mb-6">
              <label className="text-typo text-sm font-medium max-w-sm w-full block">Channels</label>
              <div className="w-full max-w-lg">
                <MultiSelectDropdown
                  id="channels"
                  displayValue="name"
                  intialValue={form.channels}
                  result={e => setForm({ ...form, channels: e.value })}
                  options={channelsCategories}
                />
              </div>
            </div>
              <div className="flex items-center gap-4 mb-6">
                <label className="text-typo text-sm font-medium max-w-sm w-full block">Affiliate Type</label>
                <div className="w-full max-w-lg">
                  <MultiSelectDropdown
                    id="channels"
                    displayValue="name"
                    intialValue={form.affiliate_types}
                    result={e => setForm({ ...form, affiliate_types: e.value })}
                    options={affiliateTypeCategories}
                  />
                </div>
              </div>

              <div className="flex items-center gap-4 mb-6">
                <label className="text-typo text-sm font-medium max-w-sm w-full block">Website URL</label>
                <div className="w-full max-w-lg">
                  <input type='text' className='shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary' placeholder='Website URL'
                    value={form.website}
                    onChange={e => setForm({ ...form, website: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div className="flex items-center gap-4 mb-6">
                <label className="text-typo text-sm font-medium max-w-sm w-full block">
                  Site Name
                </label>
                <div className="w-full max-w-lg">
                  <input
                    type="text"
                    value={form?.site_name}
                    required
                    name="site name"
                    placeholder="site name"
                    onChange={(e) => {
                      setForm({ ...form, site_name: e.target.value });
                    }}
                    // onBlur={handleBlur}
                    className="shadow-box border !border-grey bg-white  w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                  /></div>
              </div>

              <div className="flex items-center gap-4 mb-6">
                <label className="text-typo text-sm font-medium max-w-sm w-full block">
                  Weekly Unique Visitors
                </label>
                <div className="w-full max-w-lg">
                  <SelectDropdown
                    displayValue="name"
                    placeholder="Select"
                    options={[{ id: "1-500", name: "1-500" }, { id: "501-1000", name: "501-1000" }, { id: "1000-1500", name: "1500-2000" }, { id: "2000-2500", name: "2000-2500" }, { id: "2500-3000", name: "2500-3000" }]}
                    intialValue={visitors}
                    result={e => { setVisitor(e.value) }}
                    noDefault={true}
                  /></div>
              </div>

              <div className="flex items-center gap-4 mb-6">
                <label className="text-typo text-sm font-medium max-w-sm w-full block">
                  Years Online
                </label>
                <div className="w-full max-w-lg">
                  <SelectDropdown
                    displayValue="name"
                    placeholder="Select Years"
                    options={[{ id: "1", name: "1" }, { id: "2", name: "2" }, { id: "3", name: "3" }, { id: "4", name: "4" }, { id: "5", name: "5" }]}
                    intialValue={form.years_online}
                    result={e => { setForm({...form , years_online: e.value }) }}
                    noDefault={true}
                  /></div>
              </div>

            <div className="flex justify-between items-center mb-6">
              <h4 className="text-2xl font-semibold text-[#111827]">Address Detail</h4>
            </div>

            <div className="grid grid-cols-2 gap-4 ">
              <div className="">
                <label className="text-sm teaxt-neutral-700 ">
                  Address
                  <span className="text-danger">*</span>
                </label>
                <GooglePlaceAutoComplete
                  value={form.address}
                  result={addressResult}
                  id="address"
                  placeholder=""
                  key={'Address'}
                />
              </div>
              <div className="">
                <label className="text-sm teaxt-neutral-700">
                  State
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form?.state}
                  required
                  name="state"
                  placeholder="State"
                  onChange={(e) => {
                    // setAddressError('')
                    setForm({ ...form, state: e.target.value });
                  }}
                  // onBlur={handleBlur}
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                />
              </div>
              <div className="">
                <label className="text-sm teaxt-neutral-700">
                  City
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form?.city}
                  required
                  name="state"
                  placeholder="City"
                  onChange={(e) => {
                    // setAddressError('')
                    setForm({ ...form, city: e.target.value });
                  }}
                  // onBlur={handleBlur}
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                />
              </div>
              <div className="">
                <label className="text-sm teaxt-neutral-700">
                  Zipcode
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  pattern="^[a-zA-Z0-9]+$"
                  onKeyPress={(e) => {
                    // setAddressError('')
                    var regex = new RegExp("^[a-zA-Z0-9]+$");
                    var key = String.fromCharCode(
                      !e.charCode ? e.which : e.charCode
                    );
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  min={0}
                  value={form?.pincode}
                  required
                  name="pincode"
                  placeholder="Zipcode"
                  onChange={(e) => {
                    setForm({ ...form, pincode: e.target.value });
                  }}
                  // onBlur={handleBlur}
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                />
              </div>
              <div className="">
                <label className="text-sm teaxt-neutral-700">
                  Country
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form?.country}
                  required
                  name="pincode"
                  placeholder="Country"
                  onChange={(e) => {
                    // setAddressError('')
                    setForm({ ...form, country: e.target.value });
                  }}
                  // onBlur={handleBlur}
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                />
              </div>
            </div>

            <div className="col-span-12 md:col-span-12 mt-3">
              <label className='lablefontcls mb-2 inline-flex'>About you</label><br></br>

              <textarea
                value={form.aboutUs}
                rows={5}
                onChange={(e) =>setForm({ ...form, aboutUs: e.target.value })}
                className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-30 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                required
              ></textarea>
              <br />
            </div>

              <div className='col-span-12 md:col-span-12'>
                <div className="flex justify-between items-center mb-3">
                  <label className="text-xl font-semibold">Social Profile</label>
                </div>

                {platforms.map((platform, index) => (
                        <div className="flex items-end gap-2 mb-3 border border-gray-100 p-4 rounded-xl" key={index}>
                            <div className="flex-grow">
                                <label className="text-typo text-sm font-medium max-w-sm w-full block mb-2">{platform.name} URL:</label>
                                <input
                                    type="text"
                                    className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                                    value={platform.url}
                                    onChange={(e) => handlePlatformUrlChange(index, e.target.value)}
                                    placeholder={`${platform.name} URL`}
                                />
                            </div>
                        </div>
                    ))}
  
              </div>
            <div className="mt-4 flex gap-4 justify-end">
              <Link to="/profile" className="cursor-pointer relative rounded-large bg-[#fff] !px-4 text-sm text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                Discards
              </Link>
              <button type="submit" className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 !bg-primary rounded-extra_large shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Html;
