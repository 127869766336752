import React, { Suspense, useEffect } from "react";
import {
  ToastContainer,
} from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import configureStore from "./config";

import "react-pagination-js/dist/styles.css";
// import "./scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
// progressbar;
import "react-circular-progressbar/dist/styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-phone-input-2/lib/style.css";
import { Auth } from "./methods/auth";
/************ store configration *********/
import { createBrowserHistory } from "history/cjs/history";
import noPages from "./pages/404";
// import socket from "./models/socket.model";
import { toast } from "react-toastify";
import RequestData from "./pages/AffiliateRequest/RequestData";
import AffiliateMessages from "./pages/Affiliates/AffiliateMessages";
import MessageDetail from "./pages/Affiliates";
const Login = React.lazy(() => import("./pages/Login"));
const SignUp = React.lazy(() => import("./pages/Signup"));
const Dashboard = React.lazy(() => import("./pages/DashboardPages"));
const Forgotpassword = React.lazy(() => import("./pages/Forgotpassword"));
const Profile = React.lazy(() => import("./pages/Profile"));
const ChangePassword = React.lazy(() =>
  import("./components/Profile/ChangePassword")
);
import { Logo } from "./actions/Logo";
import EditProfile from "./components/Profile/Edit";
import SupplierData from "./pages/SendRequest/SendRequest";
import RequestDetail from "./pages/AffiliateRequest/detail/detail";
import TrackingData from "./pages/Tracking/tracking";
import SupplierDetail from "./pages/SendRequest/detail/detail";

// Remove useNavigate and its dependencies
const { persistor, store } = configureStore(history);

export default () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);
  localStorage.removeItem("joinedRoom");
  localStorage.removeItem("joinedPlatform");

  const user = useSelector((state) => state.user);
  // const Navigate = useNavigate();

  useEffect(() => {
    dispatch(Logo("Logo"));
  }, []);

  useEffect(() => {
    console.log(data, "This is the Logo Data");
  }, [data]);

  return (
    <>
      <Suspense
        fallback={
          <div>
            <div id="loader" className="loaderDiv">
              <div>
                <img
                  src="/assets/img/loader.gif"
                  alt="logo"
                  className="pageLoader"
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
          </div>
        }
      >
        <Router locationProp={createBrowserHistory()}>
          <Routes>
            <Route
              exact={true}
              store={store}
              path="/login"
              element={<Login />}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile/edit"
              element={<EditProfile />}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile/change-password"
              element={<ChangePassword />}
            />

            <Route
              exact={true}
              store={store}
              path="/signup"
              element={<SignUp />}
            />

            <Route
              exact={true}
              store={store}
              path="/forgotpassword"
              element={<Forgotpassword />}
            />

            <Route
              exact={true}
              path="/request"
              store={store}
              requireAuth={Auth}
              element={<RequestData />}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile"
              element={<Profile />}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/suppliers"
              element={<SupplierData />}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/request/detail/:id"
              element={<RequestDetail />}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/dashboard"
              element={<Dashboard />}
            />

            <Route
              exact={true}
              path="/messages"
              store={store}
              requireAuth={Auth}
              element={<AffiliateMessages />}
            />
            <Route
              exact={true}
              path="/messages/:id"
              store={store}
              requireAuth={Auth}
              element={<MessageDetail />}
            />

            <Route
              exact={true}
              path="/trackData"
              store={store}
              requireAuth={Auth}
              element={<TrackingData />}
            />

            <Route
              exact={true}
              path="/supplier/detail/:id"
              store={store}
              requireAuth={Auth}
              element={<SupplierDetail/>}
            />


            <Route exact={true} store={store} path="*" element={noPages} />

            <Route exact path="/" element={<Navigate to="/login" />}>
              {/* <Navigate to="/login" /> */}
            </Route>
          </Routes>
        </Router>
      </Suspense>

      <audio src="/assets/notification.mp3" id="audioFile" />
      <div id="loader" className="loaderDiv !hidden">
        <div>
          <img
            src="/assets/img/loader.gif"
            width="auto"
            height="auto"
            alt="logo"
            className="pageLoader"
          />
        </div>
      </div>
      <ToastContainer

      />
    </>
  );
};
