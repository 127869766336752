import React from "react";
import { Link } from "react-router-dom";
import methodModel from "../../../methods/methods";
import Sidebar from "../sidebar";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { FiMenu, FiX, FiLogOut, FiChevronDown } from "react-icons/fi";
import ApiClient from "./../../../methods/api/apiClient";
import datepipeModel from "../../../models/datepipemodel";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoHelpOutline } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { FcIdea } from "react-icons/fc";
const Html = ({
  isOpen,
  toggle,
  searchHandle,
  search,
  user,
  unreadCount,
  isOpen1,
  searchChange,
  clear,
  Logout,
}) => {
  const PathName = window.location.pathname;
  const [activePlan, setActivePlan] = useState();
  useEffect(() => {
    // api/my/plan
    ApiClient.get("api/my/plan", {}, '', '', { apiCall: true }).then((res) => {
      if (res.success) {
        setActivePlan(res.data);
      }
    });
  }, []);
  const history = useNavigate()
  return (
    <nav
      className={`${isOpen ? "min-sidebar w-[calc(100%-78px)]" : "w-[calc(100%-280px)] "
        } shadow-btn py-1.5 bg-white  fixed transition-[width] duration-300 ml-auto right-0 z-50 flex items-center !px-5
      `}>
      <button
        onClick={toggle}
        className="h-9 w-9 shrink-0 shadow-btn hover:shadow-none p-1 rounded-lg border border-gray-100 !text-primary">
        {!isOpen ? (
          <FiMenu className="w-full h-full" />
        ) : (
          <FiX className="w-full h-full" />
        )}
      </button>

      


      <Menu as="div" className="relative w-full flex items-center justify-end ml-auto text-left">
        {activePlan?.on_trial ? (
          <>
            <div className="mr-10 max-w-[462px] w-full text-sm font-medium h-10 text-[#2B2B2B] flex items-center gap-[3px] py-2 px-4 rounded-large bg-[#E0F4D3]">
              Note: You are on trail period. Expires on {" "}
              <span className="underline  underline-offset-4	">
                {datepipeModel.date(
                  activePlan?.validUpTo,
                  user.companyDateFormat
                )}
              </span>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="flex items-center relative">
          {user?.order_syncing ? (
            <>
              <div className="mr-4 min-w-[240px] text-sm font-medium h-10 text-[#2B2B2B] flex items-center gap-[3px] py-2 px-4 rounded-large bg-[#E0F4D3]">
                Your Sales data is syncing now
              </div>
            </>
          ) : (
            <></>
          )}
       
          <div className="mr-4 bg-gray-100 cursor-pointer  border hover:bg-primary border-1 hover:text-white shadow-sm ease-out duration-300  rounded-full p-2">
            <div className="relative">
              <IoHelpOutline title="Help" className="text-[24px]" />
            </div>
          </div>
          {/* <div className="mr-2 bg-gray-100 cursor-pointer  border hover:bg-primary border-1 hover:text-white shadow-sm ease-out duration-300  rounded-full p-2">
            <div className="relative">

              <IoIosNotificationsOutline className="text-[24px]" onClick={e => history(`/omni-channels/live-chat`)} />
              {unreadCount && unreadCount != 0 ? <span className="noti-fi h-2 w-2 absolute -top-2 -right-2"></span> : null}
            </div>
          </div> */}

          {user?.product_syncing ? (
            <>
              <div className="mr-1.5 min-w-[200px] text-xs w-[20px] text-white p-3 rounded-large bg-primary">
                Your Products data is syncing now
              </div>
            </>
          ) : (
            <></>
          )}
          <Menu.Button className="hover:bg-black/5 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 flex items-center justify-center w-full gap-2 px-2 py-2 rounded-large">
            <img
              alt="image"
              src={methodModel.userImg(user.image)}
              className="object-cover w-10 h-10 rounded-full"
              width="40"
              height="40"
            />
            <div>
              <p className="font-medium text-[#2D3036] text-sm">{user.fullName}</p>
            </div>
            <FiChevronDown className="w-5 h-5 text-[#2D3036]" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="ring-1 ring-black/5 z-[999999] focus:outline-none w-60 !rounded-large absolute top-14 right-0 mt-1.5 origin-top-right bg-white divide-y divide-gray-100 shadow-lg">
            <div className=" px-2 py-2">
              <Menu.Item>
                <Link
                  to={user?.role?.name == "Affiliate"  ?  "/profile"  : "/profile"}
                  className="group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                  <FaRegUser />
                  Profile
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to="/profile/change-password"
                  className="group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                  <IoSettingsOutline />
                  Change Password
                </Link>
              </Menu.Item>
            </div>
            <div className="px-2 py-2">
              <Menu.Item>
                <button
                  onClick={() => Logout()}
                  className="group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                  <FiLogOut />
                  Logout
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {isOpen1 ? (
        <div className="mobi-dropdown w-full">
          <Sidebar />
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default Html;
