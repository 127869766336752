import { useEffect, useRef, useState } from "react";
import Layout from "../../components/global/layout";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import Pagination from 'react-pagination-js'
import { MdClear } from "react-icons/md";
import PaginationTextView from "../../components/common/PaginationTextView";
import SelectDropdown from "../../components/common/SelectDropdown";


const OPTIONS = [{ option: "Country of Origin" }, { option: "Option 1" }];

const TrackingData = () => {
  const user = useSelector(state => state.user);
  const [Total, setTotal] = useState(0);
  const [Data, setData] = useState([]);
  const [datafilters, setdatafilters] = useState({ page: 1, count: 50, search: "", affiliate_id: user?.id || user?._id })
  const dataRef = useRef([]);

  const GetData = (p = {}) => {
    // if()
    const newfilters = { ...datafilters, ...p }
    loader(true);
    ApiClient.get(`api/affiliate/bookings`, newfilters, "", "", { apiCall: true }).then(res => {
      if (res.success) {
        setTotal(res.total)
        setData(res?.data)
        dataRef.current = res?.data
      }
      loader(false);
    })
  }

  const uniqueKeys = Data?.reduce((headers, itm) => {
    if (itm?.booking_data && typeof itm.booking_data === 'object') {
      Object.keys(itm.booking_data).forEach(key => {
        if (!headers.includes(key)) {
          headers.push(key);
        }
      });
    }
    return headers;
  }, []);

  useEffect(() => {
    GetData()
  }, [])

  const HandleSubmit = (e) => {
    e.preventDefault();
    GetData();
  }

  const clear = () => {
    let f = {
      search: '',
      affiliate_types: '',
      channels: []
    }
    filter(f)
  }

  const filter = (p = {}) => {
    setdatafilters({ ...datafilters, ...p })
    GetData(p);
  }

  const sorting = (key) => {
    let sorder = "asc";
    if (datafilters.key == key) {
      if (datafilters.sorder == "asc") {
        sorder = "desc";
      } else {
        sorder = "asc";
      }
    }

    let sortBy = `${key} ${sorder}`;
    filter({ sortBy, key, sorder });
  };

  return (
    <Layout>
      <div className="flex items-center justify-between mb-6">
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-semibold text-[#111827]">
            Tracking Data
          </h4>
          {/* <p className="text-sm font-normal text-[#75757A]">
            Here you can see all about all Suppliers
          </p> */}
        </div>

        <div className="flex gap-3">
        </div>
      </div>

      <div className="bg-white shadow-box rounded-lg w-full border border-grey">
        <div className="flex items-start gap-3 justify-between p-6">
          {/* <div className="flex items-center gap-2">

            <form className="w-full outline-none  p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey" onSubmit={HandleSubmit}>
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
              <input
                className="w-full outline-none ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                placeholder="Search"
                value={datafilters.search}
                onChange={e => setdatafilters({ ...datafilters, search: e.target.value })}
              />
            </form>
            <button type="submit" className="!bg-primary border !border-primary h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full" onClick={e => HandleSubmit(e)}>
              Search
            </button>

            {(datafilters.search) ? <>
              <button type="button" onClick={clear} className="!bg-primary border !border-primary flex items-center gap-1 h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full ">
                <MdClear className="text-xl text-white" />
                Clear
              </button>
            </> : <></>}
          </div> */}

        </div>
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="border-y border-[#EAECF0]">
              <tr className=" border-y border-[#EAECF0]">
                <th
                  onClick={() => sorting('fullName')}
                  scope="col"
                  className="cursor-pointer min-w-[250px] text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3">
                  Affiliate Id
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                {Data?.reduce((headers, itm) => {
                  if (itm?.booking_data && typeof itm.booking_data === 'object') {
                    Object.keys(itm.booking_data).forEach(key => {
                      if (!headers.includes(key)) {
                        headers.push(key);
                      }
                    })
                  };
                  return headers;
                }, []).map(key => (
                  <th key={key} className="cursor-pointer  text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-center bg-[#F7FAFF] !py-3" scope="col">{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataRef?.current?.map((item, index) => (<tr>
                <td className=" text-typo !px-5 text-sm font-normal !border-l-0 shrink-0 !py-3 !border text-center border-[#EAECF0]">
                  <div className="flex items-center cursor-pointer gap-3.5">
                    <div className="text-left">
                      <span className="block text-left">{item?.affiliate_id || "--"}</span>
                    </div>
                  </div>
                </td>

                {uniqueKeys.map(key => {
                  const value = item?.booking_data && item.booking_data[key] !== undefined ? item.booking_data[key] : null;
                  return <td key={key} className=" text-typo !px-5 text-sm font-normal !border-l-0  !py-3 !border text-center border-[#EAECF0]">{value || "--"}</td>;
                })}
              </tr>))}
            </tbody>
          </table>
        </div>
        {Data?.length == 0 ? (
          <div className="py-3 text-center">No Data</div>
        ) : (
          <></>
        )}
        {Total > datafilters?.count && (
          <div className="paginationWrapper flex items-center justify-between p-6">
            <div className="table_rowings w-96">
              <p className="text-gray-600 text-sm"> {PaginationTextView(datafilters.page, datafilters.count, Total)}</p>
            </div>
            <Pagination
              currentPage={datafilters.page}
              totalSize={Total}
              sizePerPage={datafilters.count}
              changeCurrentPage={e => {
                setdatafilters({ ...datafilters, page: e });
                GetData({ page: e });
              }}
            />
          </div>
        )}
      </div>
      {/* <Modal
        isHeader={false}
        isFooter={false}
        isOpen={ShowConfirmModal}
        content={<>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="subject" className="mb-2 inline-flex">Subject:</label>
              <input
                type="text"
                className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message">Message:</label>
              <Editor
                textareaName="content"
                value={message}
                className="tuncketcls"
                onEditorChange={(newValue, editor) => {
                  setMessage(newValue);
                }}
                apiKey={tinymcModel.apiKey}
                init={{
                  selector: "textarea#autocompleter-cardmenuitem",
                  height: 250,
                  plugins: tinymcModel.plugins,
                  toolbar: tinymcModel.toolbar,
                  setup: (editor) => {
                    const onAction = (
                      autocompleteApi,
                      rng,
                      value
                    ) => {
                      editor.selection.setRng(rng);
                      editor.insertContent(value);
                      autocompleteApi.hide();
                    };
                    const getMatchedChars = (pattern) => {
                      return specialChars.current.filter(
                        (char) => char.text.indexOf(pattern) !== -1
                      );
                    };
                    editor.ui.registry.addAutocompleter(
                      "specialchars_cardmenuitems",
                      {
                        trigger: "$",
                        minChars: 0,
                        columns: 1,
                        highlightOn: ["char_name"],
                        onAction: onAction,
                        fetch: (pattern) => {
                          return new Promise((resolve) => {
                            const results = getMatchedChars(
                              pattern
                            ).map((char) => ({
                              type: "cardmenuitem",
                              value: "${" + char.value + "}",
                              label: char.text,
                              items: [
                                {
                                  type: "cardcontainer",
                                  direction: "vertical",
                                  items: [
                                    {
                                      type: "cardtext",
                                      text: char.text,
                                      name: "char_name",
                                    },
                                  ],
                                },
                              ],
                            }));
                            resolve(results);
                          });
                        },
                      }
                    );
                  },
                }}
                required
              />
            </div>
            <div className="flex justify-end gap-3 items-center">
            <a className="text-sm px-4 py-2 bg-gray-100 text-black font-medium mt-3 rounded-large cursor-pointer" onClick={() => setShowConfirmModal(false)}>Cancel</a>
            <button type="submit" className="text-sm px-4 py-2 bg-primary text-white font-medium mt-3 rounded-large">Sent Message</button>
            </div>
          </form>
        </>}
      /> */}
    </Layout>
  );
};

export default TrackingData;
