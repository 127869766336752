export const rolePermissions=[
    {
      name:'Dashboard',
      key:'Dashboard'
    },
  {
    name: 'Communication Channel',
    key: 'OmniChannel'
  },
    {
      name:'Sales',
      key:'Sales'
    },
    {
      name:'Products',
      key:'Products'
    },
    {
      name:'Price Management',
      key:'DynamicPricing'
    },
    {
      name:'Customers',
      key:'Customers'
    },
  {
    name: 'Waivers Templates',
    key: 'Waivers'
  },
  {
    name: 'Reviews',
    key: 'Reviews'
  },
    {
      name:'Marketing',
      key:'Marketing'
    },
    
    {
      name:'Reseller',
      key:'Reseller'
    },
    {
      name:'Contracts Templates',
      key:'Contracts'
    },
    
    {
      name:'Financials',
      key:'Financials'
    },
    
    {
      name:'Company Details',
      key:'Company'
    },
  {
    name: 'Data Connection',
    key: 'DataConnection'
  },
    {
      name:'Users',
      key:'Users'
    },
    {
      name:'Costing Templates',
      key:'Costing'
    },{
      name:'Email Settings',
      key:'EmailSetting'
    }
  ]

  export const rolePermission=[
    {name:"Read",key:'read'},
    // {name:"Add",key:'add'},
    {name:"Edit",key:'edit'},
    {name:"Delete",key:'delete'},
  ]

  export const roleGetAllKeys=()=>{
    let keys={};
    rolePermissions.map(itm=>{
        rolePermission.map(pitm=>{
        keys={...keys,[`${pitm.key}${itm.key}`]:false}
      })
    })

    return keys
  }


export const userType={ id:'',fullName: '',role:'',subRole:'', email: '', mobileNo: '', dialCode: '',aboutUs:'',address:'',image:'',logo:'', social_profiles:[],
  aboutus:'',city:'',country:'',website:''}
export const companyType={ id:'',companyName:'',addPartner:'',contactPerson:'',position:'',financialYear:'',bussinessNumber:'',companyDateFormat:'',currency:'',companyAddress:'',contactName:'',contactemail:'',companydialcode:'',companymobileno:'',companytimezone:'',productcategory:'',website:'',country:'',city:'',state:'',zipcode:'',lat:'',lng:'',logo:'',companyCurrencyFormat:''}
export const CategoryType={id:'',name:'',catType:'',subParentCategory:'',description:'',image:'',order:'',parentCategory:'',status:'active',icon:'',banner:'',altImageName:'',altIconName:'',bannerOverlayHeading:'',bannerOverlayBody:'',description:'',featured:'No',urlKey:'',metaTitle:'',metaDescription:'',keywords:''}
export const roleType={id:'',name:'',status:'active',permissions:roleGetAllKeys()}
export const resellerCategoryType={id:'',name:'',catType:'Reseller',description:'',image:''}
export const planType={id:'',name:'',price:'',status:'active',interval:'Monthly',currencyId:'',category:'',recomended:'',feature:[]}
export const continentType={id:'',name:'',status:'active'}
export const featureType={id:'',name:'',status:'active'}
export const emailType={id:'',subject:'',title:'',content:'',status:'active'}
export const cityType={id:'',name:'',status:'active',countryId:'',regionId:'',continent:''}
export const bookingSystemType={id:'',name:'',apiKey:'',secretKey:''}
export const posType = { id: '', name: '', secretKey: '', apiKey: '', url: '', username: '', password: '', displayName: '', fareharborAppKey: '', fareharborUserKey :'',company_name:''}
export const crmType={id:'',image:'',content:'',resellerSign:'',resellerType:'',sameAsAbove:false,resellerCollectData:[],body:[],yourSignature:[],resellerSignature:[],categoryToApply:'',products:[],showLogo:'',showAddress:'',address:'',internalName:'',externalName:'',firstSection:'',rateSheetName:'',rateSheet:'',allProducts:'',applyRateForAll:"",commisionRate:'',categoryToApply:[],applyRateForAllProducts:'',productCommisionRate:'',}
export const couponType={id:'',title:'',status:'active',amountOrPer:'',description:'',couponCode:'',usesPerCoupon:'',usesPerCustomer:'',dateFrom:'',dateTo:'',discountType:'',discountAmount:'',}
export const holidaysType={id:'',name:'',status:'active',discOrPre:'',type:'',amountOrPercent:'',number:'',applyFor:[],preOrPost:'',preDays:'',postDays:'',changesApply:'',changesDate:'',changesDateTo:'',country:''}
export const resellerType={id:'',name:"",logo:"",city:'',lat:'',lng:'',state:'',zipcode:'',companyName:'',website:'',trading:'',abn:'',position:'',category:"",contractDate:"",booking:"",pax:"",country:'',contactPerson:'',contactPhone:'',contactEmail:'',email:'',address:''}
export const earlybirdpricingType={id:'',name:'',discOrPre:'',onOfDays:'',priceTypes:[],status:'active',changesDate:'',lastMinutePricingToDate:'',inventory:'',lastMinutePricingFromDate:'',applyPriceType:'',availablespaces:'',startDate:'',endDate:'',applyEarlyBirdPricing:[{}],changesDateTo:'',notApply:'',notApplyCondition:'',notApplicableFor:[],blackOutDates:[],amountOrPercent:'',number:'',applyToDaysTimeSlot:'yes',daysToApply:[],timeSlots:[],country:''}
