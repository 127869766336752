export const ITEM = [
  {
    badgeLabel: "Total Sales",
    value: "$3,4598",
    increment: "2.11%",
  },
  {
    badgeLabel: "Total orders",
    value: "34K",
    increment: "2.11%",
  },
  {
    badgeLabel: "Total Pax",
    value: "345.8K",
    increment: "2.11%",
  },
  {
    badgeLabel: "Additional Revenue",
    value: "$4,598",
    decrement: "2.11%",
  },
  {
    badgeLabel: "Average Order value",
    value: "$67.00",
    decrement: "2.11%",
  },
  {
    badgeLabel: "Commission Paid",
    value: "$2,980",
    increment: "2.11%",
  },
  {
    badgeLabel: "Time",
    value: "56 Days",
    increment: "2.11%",
  },
];

export const ITEM_1 = [
  {
    badgeLabel: "Total Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Discounted Offered",
    key: '',
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissionPaid',
  },
  {
    badgeLabel: "Net Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings'
  }
];

export const ITEM_2 = [
  {
    badgeLabel: "Reseller Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissionPaid',
  },
  {
    badgeLabel: "Net Sales from Resellers",
    key: 'totalSale',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings'
  }
];

export const ITEM_3 = [
  {
    badgeLabel: "Invoice Raised",
    key: 'invoice_raised',
  },
  {
    badgeLabel: "Invoice Paid",
    key: 'invoice_raised',
  },
  {
    badgeLabel: "Balance Owing",
    key: 'invoice_due',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings',
  }
];


export const ITEM_4 = [

  {
    badgeLabel: "Sales",
    key: 'sales',
    type: 'currency'
  },
  {
    badgeLabel: "Bookings",
    key: 'bookings',
    type: 'num'
  },
  {
    badgeLabel: "AOV",
    key: 'avg_order_value',
    type: 'num'
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissions',
    type: 'currency'
  },
  {
    badgeLabel: "Ops Costs",
    key: 'cost',
    type: 'currency'
  },
  {
    badgeLabel: "Total Costs",
    key: 'total_costs',
    type: 'currency'
  },
  {
    badgeLabel: "Profit/Loss",
    key: 'profit',
    type: 'mix'
  },
  {
    badgeLabel: "% of tours - Profit",
    key: 'profit_per',
    type:'per'
  },
  {
    badgeLabel: "% of tours - Breakeven",
    key: 'breakeven_per',
    type:'per'
  },
  {
    badgeLabel: "% of tours - Loss",
    key: 'loss_per',
    type:'per'
  },
];

export const CHART_OPTIONS = [
  { option: "Bar Chart" },
  { option: "Pie Chart" },
  { option: "Line Chart" },
];

export const MONTH_OPTIONS = [
  { option: "This Month" },
  { option: "Last Month" },
];

export const colourStyles = {
  menuList: (styles) => ({
    ...styles,
    "::-webkit-scrollbar": {
      width: "6px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#ddd",
    },
    fontSize: 14,
  }),
  control: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    borderWidth: 0,
    minHeight: 40,
    backgroundColor: "white",
    borderRadius: 24,
    textAlign: "left",
    minWidth: 180,
    boxShadow:
      "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
    outline: state.isFocused ? "2px solid #481196" : "",
    fontSize: 14,
    paddingLeft: 4,
    paddingRight: 4,
  }),
  menu: (styles) => ({
    ...styles,
    outline: "2px solid #481196",

  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#481196"
          : isFocused
            ? "#481196"
            : undefined,
      color: isFocused ? "#fff" : isDisabled ? "#ccc" : isSelected ? "#fff" : "#000",
      fontWeight: isSelected ? 500 : 400,
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#000"
            : "#F7FAFF"
          : undefined,
        color: !isDisabled ? isSelected && "#000" : undefined,
      },
    };
  },
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#75757A"
  }),
  input: (styles) => ({
    ...styles,
    fontSize: 14,
    color: "#1D2433",
    fontWeight: 400,
  }),
  placeholder: (styles) => ({ ...styles, fontWeight: 400, fontSize: 14 , color: "#1D2433" }),
  singleValue: (styles) => ({ ...styles }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  })
};
import React from 'react'
const MySelect = (props) => {
  return (
    <MySelect
      {...props}
      styles={colourStyles}
      components={{ DropdownIndicator }}
    />
  );
};

export const userTabs = [
  { title: 'User List', link: '/users' },
  { title: 'Roles', link: '/roles' },
];

export const BookingTabs = [
  { title: 'Booking System', link: '/pos' },
  { title: 'Reviews', link: '/api/review-connected?show=false' },
  { title: 'Marketing', link: '/marketing-analytics' },
  { title: 'Accounting', link: '/api/accounting' },
//  { title: 'Communication Channel ', link: '/omni-channel-setting/copy' },
  // { title: 'Communication Channel Widget', link: '/omni-channel-setting/widget' },
  // { title: 'Social Media Integration', link: '/omni-channel-setting/social/media' },
  // { title: 'Google Tag Manager', link: '/omni-channel-setting/google/tag' },
];


export const XeroTabs = [
  { title: 'Xero Invoices', link: '/accounting/invoices' },
  { title: 'Bills to Pay', link: '/accounting/bills' },
];
