import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
// import './style.scss';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import Pagination from 'react-pagination-js'
import Breadcrumb from "../../../components/common/Breadcrumb";

const SupplierDetail = (p) => {
  const history = useNavigate()
  const user = useSelector(state => state.user)
  const [filters, setFilter] = useState({ page: 1, count: 50, search: "" });
  const { id } = useParams()
  const [data, setData] = useState()
  const [products, setProducts] = useState([])
  const [productLoader, setProductLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const getDetail = (did) => {
    loader(true)
    ApiClient.get(`api/user/profile`, { id: did }).then(res => {
      if (res.success) {
        setData(res.data)
        getProducts({ addedBy: id || did })
      }
      loader(false)
    })
  };

  const back = () => {
    history(-1)
  }

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getProducts(p);
  };

  const pageChange = (e) => {
    filter({ page: e });
  };

  const getProducts = (p = {}) => {
    let f = {
      ...filters,
      ...p,
      addedBy: id
    };
    setProductLoader(true);
    ApiClient.get("api/supplier/products", f).then((res) => {
      if (res.success) {
        setProducts(res.data?.map((item) => ({ ...item, id: item?._id || item?.id })));
        // const selectedValue = dataProduct.filter((itm) => itm?.isAffiliationEnabled);
        setTotal(res.total);
      }
      setProductLoader(false);
    });
  };

  useEffect(() => {
    getDetail(id)
  }, [id])

  return (
    <Layout>
      <Breadcrumb
        links={[
          {
            name: "Supplier Data",
            link: "/suppliers",
          },
        ]}
        currentPage={"Supplier Detail"}
      />
      <div className="flex items-center">
        {/* <div>
                    <a to="/users" onClick={back}>  <i className="fa text-black fa-arrow-left mr-4 mb-3 " title='Back' aria-hidden="true"></i>dfd</a>
                </div> */}
        <h3 className="text-2xl font-semibold text-[#111827] mb-6 ">
          Supplier Detail
        </h3>
      </div>
      <div className="max-w-[900px] bg-white shadow-box border !border-grey rounded-large">
        <div className=" ">


          <div className="">
            <div className='p-6'>


              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">Name:</label>
                <div className='profiledetailscls'>{data && data?.fullName}</div>
              </div>
              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">Company Name:</label>
                <div className='profiledetailscls'>{data && data?.companyName}</div>
              </div>
              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">Allow Affiliate :</label>
                <div className='profiledetailscls'>{data && data?.isAffiliate ? <><p className='rounded-full bg-gray-100 px-4 py-1'>Yes</p></> : <><p className='rounded-full bg-red-600 px-4 text-sm text-white py-1'>No</p></>}</div>
              </div>
            </div>

                <div className="col-span-12 p-4 !pt-0">
                  <hr className="h-[1px] bg-[#EAECF0] !my-5" />
                  <label className="text-typo block text-base font-medium mb-4">Products:</label>

                  <form
                    className="flex whitespace-nowrap gap-2.5 flex-wrap !mb-4 justify-end"
                    onSubmit={(e) => {
                      e.preventDefault();
                      filter();
                    }}>
                    <input
                      type="text"
                      className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-4 !ring-primary !outline-primary disabled:!bg-gray-200"
                      placeholder="Search by Product Name"
                      value={filters.search}
                      onChange={(e) =>
                        filter({ search: e.target.value, page: 1 })
                      }
                    />
                  </form>

                  <div className="p-3 bg-[#FAFBFC] rounded-large">
                    {productLoader ? (
                      <>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                      </>
                    ) : (
                      <>
                        {products?.filter((itm) => itm?.isAffiliationEnabled)?.length == 0 ? (
                          <>
                            <div className="text-center">
                              No Products
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="w-full">
                          <table className="w-full">
                            {products?.filter((itm) => itm?.isAffiliationEnabled).map((itm) => {
                              return (
                                <tr>
                                  <td
                                    className="!p-2">
                                    <div className="block lg:flex items-center !gap-3">
                                      <img
                                        src={methodModel.noImg(
                                          itm?.images?.length
                                            ? itm?.images[0]
                                              .thumbnailUrl
                                            : ""
                                        )}
                                        className="w-6 h-6 lg:w-9 lg:h-9 mb-2 shrink-0 rounded-large"
                                      />
                                      <p className="text-xs font-normal text-[#101828] mb-0 capitalize">
                                        {itm.name}
                                      </p>
                                    </div>
                                  </td>
                                  <td
                                    className="!p-2">
                                    <p className="text-sm font-medium text-[#101828] mb-0 capitalize">
                                      {itm.categoryName || "--"}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>

                        {/* {total > filters.count ? (
                          <div className="paginationWrapper">
                            <span>
                              Show {filters.count} from {total}{" "}
                              Products
                            </span>
                            <Pagination
                              currentPage={filters.page}
                              totalSize={total}
                              sizePerPage={filters.count}
                              changeCurrentPage={pageChange}
                            />
                          </div>
                        ) : (
                          <></>
                        )} */}
                      </>
                    )}
                  </div>
                </div>

          </div>
        </div>
      </div>
    </Layout >
  );
};

export default SupplierDetail;
