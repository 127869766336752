import React, { useState } from "react"
import environment from "../../../environment";
import Select from 'react-select'

const Html = ({ searchText, search, placeholder, id, value,placeChange }) => {
  return <>
    <input compoment="GooglePlaceAutoComplete" type="text" id={'pac_input_' + id} value={searchText} placeholder={placeholder || ''} onChange={e => search(e.target.value)} className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-4 !ring-primary !outline-primary disabled:!bg-gray-200" />
  </>
}

export default Html