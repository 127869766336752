import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { login_success } from "../../../actions/user";
import "./style.scss";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import addressModel from "../../../models/address.model";

// import { Layout } from 'antd';

const EditProfile = (p) => {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const [form, setForm] = useState(userType);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [channelsCategories, setChannelsCategories] = useState([])
  const [affiliateTypeCategories, setAffiliateTypeCategories] = useState([])
  const formValidation = [{ key: "mobileNo", minLength: 10 }];
  const [visitors, setVisitor] = useState('')
  const [platforms, setPlatforms] = useState([
    { name: 'Facebook', url: '' },
    { name: 'Instagram', url: '' },
    { name: 'Twitter', url: '' },
    { name: 'YouTube', url: '' }
  ]);

  const numbers = visitors.split('-')
  const minnumber1 = Number(numbers[0]) || ''
  const maxnumber2 = Number(numbers[1]) || ''

  const handlePlatformUrlChange = (index, value) => {
    const updatedPlatforms = [...platforms];
    updatedPlatforms[index].url = value;
    setPlatforms(updatedPlatforms);
};

  console.log(form, "dfhghsdgfhsdgfh")


  const gallaryData = () => {
    loader(true);
    ApiClient.get(`api/user/profile`, { id: user?._id || user.id }).then(
      (res) => {
        if (res.success) {
          setForm({
            form,
            ...res.data,
            mobileNo: res?.data?.mobileNo || "+61",
            affiliate_types: res?.data?.affiliate_types.map((itm) => itm?.id || itm?._id),
            channels: res?.data?.channels.map((itm) => itm?.id || itm?._id),
            social_profiles: res?.data?.social_profiles,
            aboutUs: res?.data?.aboutUs,
            website: res?.data?.website,
            site_name: res?.data?.site_name,
            // affiliate_types:res?.data?.affiliate_types,
            // min_visitors:res?.data?.min_visitors,
            // max_visitors:res?.data?.max_visitors,
            years_online: res?.data?.years_online,
            pincode: res?.data?.pinCode,
          });
          setVisitor(`${res?.data?.min_visitors + "-" + res?.data?.max_visitors}`)
          setPlatforms(res?.data?.social_profiles)
          setData(res.data);
        }
        loader(false);
      }
    );
  };

  const addressResult = async (e) => {
    console.log(e.value, e.place, "dfdfgdfgjhfsdgf")
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setForm({
      ...form,
      address: e.value || "",
      city: address.city || "",
      state: address.state || "",
      country: address.country || "",
      pincode: address.zipcode || "",
    });
  };

  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation);
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // let invalid = methodModel.getFormError(formValidation, form);
    // if (invalid) return;

    let value = {
      fullName: form.fullName,
      mobileNo: form.mobileNo,
      image: form.image,
      id: user._id,
      social_profiles: platforms,
      aboutUs: form?.aboutUs,
      website: form?.website,
      site_name: form?.site_name,
      min_visitors: minnumber1,
      max_visitors: maxnumber2,
      years_online: form?.years_online,
      affiliate_types: form?.affiliate_types,
      channels: form?.channels,
      address: form?.address,
      city: form?.city,
      state: form?.state,
      country: form?.country,
      pinCode: form?.pincode
    };

    loader(true);
    ApiClient.put("api/user/profile", value).then((res) => {
      if (res.success) {
        let uUser = { ...user, ...value };
        dispatch(login_success(uUser));
        gallaryData();
        history("/profile");
        toast.success(res.message);
      }
      loader(false);
    });
  };

  const uploadImage = (e) => {
    setForm({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("api/upload/image?modelName=users", {
      file: file,
      modelName: "users",
    }).then((res) => {
      if (res.fileName) {
        let image = res.fileName;
        setForm({ ...form, image: image, baseImg: "" });
      } else {
        setForm({ ...form, baseImg: "" });
      }
      loader(false);
    });
  };

  const getChannelsCategories = () => {
    ApiClient.get(`api/categories/listing?catType=6668159767441407842c2407`).then(res => {
      if (res.success) {
        setChannelsCategories(res.data)
      }
    })
  }

  const getAffiliateTypeCategories = () => {
    ApiClient.get(`api/categories/listing?catType=6669824b78d69423b79dbc81`).then(res => {
      if (res.success) {
        setAffiliateTypeCategories(res.data)
      }
    })
  }

  useEffect(
    () => {
      if (user && user.loggedIn) {
        gallaryData();
        getChannelsCategories()
        getAffiliateTypeCategories()
      }
    },
    []
    // here '[]' is used for, loop se bachne k liye
  );

  return (
    <>
      <Html
        handleSubmit={handleSubmit}
        setForm={setForm}
        form={form}
        uploadImage={uploadImage}
        getError={getError}
        submitted={submitted}
        channelsCategories={channelsCategories}
        affiliateTypeCategories={affiliateTypeCategories}
        handlePlatformUrlChange={handlePlatformUrlChange}
        platforms={platforms}
        user={user}
        visitors={visitors}
        setVisitor={setVisitor}
        addressResult={addressResult}
      />
    </>
  );
};


export default EditProfile;
