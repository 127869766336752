import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import { LuUser2 } from "react-icons/lu";
import { BiPhone } from "react-icons/bi";
import { PiFolderSimpleUser } from 'react-icons/pi';
import Breadcrumb from '../../components/common/Breadcrumb';

const MessageDetail = (p) => {
  const history = useNavigate()
  const user = useSelector(state => state.user)
  const { id } = useParams()
  const [data, setData] = useState()

  const getDetail = (did) => {
    loader(true)
    ApiClient.get(`api/affilate/messege`, { id: did }).then(res => {
      if (res.success) {
        setData(res.data)
      }
      loader(false)
    })
  };

  const back = () => {
    history(-1)
  }

  useEffect(() => {
    getDetail(id)
  }, [id])

  return (
    <Layout>
      <Breadcrumb
        links={[
          {
            name: "Messages Data",
            link: "/messages",
          },
        ]}
        currentPage={"Message Detail"}
      />
      <div className="text-right">
        <div>
          <a to="/message" onClick={back}>  <i className="fa fa-arrow-left mr-4 mb-3 " title='Back' aria-hidden="true"></i></a>
        </div>
      </div>
      <div className="">
        <div className="sideclass ">
          <h3 className="text-2xl font-semibold text-[#111827] mb-6">
            Message Detail
          </h3>

          <div className='max-w-[900px] bg-white shadow-box border !border-grey rounded-large'>


            <div className=" p-6">
              <div className=" mb-3">
                <div className='flex items-center gap-2'>
                  <label className="text-typo text-sm font-medium">Subject:</label>
                  <div className='bg-gray-100 px-2 py-1 rounded-full text-sm border border-blue-500'>{data && data?.subject}</div>
                </div>
              </div>
              <div className=" mb-3">
                <label className="text-typo text-sm font-medium">Message</label>
                <div className='profiledetailscls'> <p
                  className="text-md text-neutral-600">{data?.message}</p></div>
              </div>

              <div className='border-t border-gray-100 pt-4 mt-4'>
                <h3 className="text-typo text-lg font-medium mb-4">
                  Sender Detail
                </h3>

                <div className="flex items-center gap-2 mb-3">
                  <label className="text-typo text-sm font-medium border border-gray-100 rounded-full p-2 shadow-sm"><LuUser2 className='text-xl' />              </label>
                  <div className='text-sm'>{data && data?.sender?.fullName}</div>
                </div>

                <div className="flex items-center gap-2 mb-3">
                  <label className="text-typo text-sm font-medium border border-gray-100 rounded-full p-2 shadow-sm"><PiFolderSimpleUser className='text-xl' />              </label>
                  <div className='text-sm'>{data && data?.sender?.email}</div>
                </div>

                <div className="flex items-center gap-2 mb-3">
                  <label className="text-typo text-sm font-medium border border-gray-100 rounded-full p-2 shadow-sm"><BiPhone className='text-xl' />              </label>
                  <div className='text-sm'>+{data && data?.sender?.mobileNo}</div>
                </div>
              </div>

              <div className='border-t border-gray-100 pt-4 mt-4'>
                <h3 className="text-typo text-lg font-medium mb-4">
                  Receiver Detail
                </h3>

                <div className="flex items-center gap-2 mb-3">
                  <label className="text-typo text-sm font-medium border border-gray-100 rounded-full p-2 shadow-sm"><LuUser2 className='text-xl' />              </label>
                  <div className='text-sm'>{data && data?.receiver?.fullName}</div>
                </div>

                <div className="flex items-center gap-2 mb-3">
                  <label className="text-typo text-sm font-medium border border-gray-100 rounded-full p-2 shadow-sm"><PiFolderSimpleUser className='text-xl' />              </label>
                  <div className='text-sm'>{data && data?.receiver?.email}</div>
                </div>

                <div className="flex items-center gap-2 mb-3">
                  <label className="text-typo text-sm font-medium border border-gray-100 rounded-full p-2 shadow-sm"><BiPhone className='text-xl' />              </label>
                  <div className='text-sm'>+{data && data?.receiver?.mobileNo}</div>
                </div>
              </div>



              {/* <div className="col-md-12 mb-3">
                            <label className="text-typo text-sm font-medium">About Us</label>
                            <div className='profiledetailscls'>{data && data.aboutUs}</div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout >

  );
};

export default MessageDetail;
