let host = document.location.host;
let apiUrl ='https://endpoint.dazhboards.com/'
if(host.includes('localhost')){
  apiUrl='http://endpoint.jcsoftwaresolution.com:6030/'
  apiUrl='https://endpoint.dazhboards.com/'
  
}else if(host.includes('stage.dazhboards.com/')){
  apiUrl='https://endpoint.dazhboards.com/'
}else if(host.includes('jcsoftwaresolution.com')){
  apiUrl='http://endpoint.jcsoftwaresolution.com:6030/'
}else{
  apiUrl='https://endpoint.dazhboards.com/'
}
const environment = { 
    chatbot_api:"https://bot.dazhboards.com/",
  api: "https://app2api.dazhboards.com/",
  bookingApi:'https://app2api.dazhboards.com/',
url:'https://app2.dazhboards.com/',
  // api:"http://192.168.1.23:6039/",
    adminRoleId:'64b152a909d268f038611929',
    userRoleId:'64b15102b14de6c28838f7d2',
    partnerRole:'64b15102b14de6c28838f7d2',
  SubRolePartner:'65d305b193650817f7620002',
    // fbAppId:'650078173094925',
    // fbConfig_id:null,
    fbAppId:'',
    fbConfig_id:'',

    // map_api_key:'AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs',
    map_api_key:'',
    resellerTypeId:'64b23b7d690d1d5f7ae76102',
    productTypeId:'64a7d198fa039f179c0320ca',
  OPENAI_API_KEY:''
  };
  export default environment;
