import { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import loader from "../../methods/loader";
import environment from "../../environment";
import axios from "axios";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import Pagination from 'react-pagination-js'
import { MdClear } from "react-icons/md";
import PaginationTextView from "../../components/common/PaginationTextView";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { IoMdCopy } from "react-icons/io";

const OPTIONS = [{ option: "Country of Origin" }, { option: "Option 1" }];

const RequestData = () => {
  const user = useSelector(state => state.user);
  const [Total, setTotal] = useState(0);
  const history = useNavigate();
  const [Data, setData] = useState([]);
  const [datafilters, setdatafilters] = useState({ page: 1, count: 50, search: "", affiliate_id: user?.id || user?._id })

  const GetData = (p = {}) => {
    const newfilters = { ...datafilters, ...p }
    loader(true);
    ApiClient.get(`api/affiliate/requests`, newfilters, "", "", { apiCall: true }).then(res => {
      if (res.success) {
        setTotal(res.total)
        setData(res.data)
      }
      loader(false);
    })
  }

  const statusVerified = (itm, id) => {
    loader(true);
    ApiClient.put('api/supplier/status', {
      // "supplier_id": supplier_id,
      "id": id,
      "request_status": itm
    }).then((res) => {
      if (res.success) {
        GetData({ page: 1 });
      }
      loader(false);
    });
  };

  useEffect(() => {
    GetData()
  }, [])

  const HandleSubmit = (e) => {
    e.preventDefault();
    GetData();
  }

  const exportfun = async (e) => {
    e.preventDefault()
    const token = await localStorage.getItem("token");
    let newfilters = { ...datafilters, export_to_xls: 'Y' }
    if (newfilters.email) newfilters.email = newfilters.email.toString()
    const req = await axios({
      method: "get",
      url: `${environment.api}api/customer/bookings-stats`,
      responseType: "blob",
      params: newfilters,
      body: { token: token }
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `customerdata.xlsx`;
    link.click();
  }

  const clear = () => {
    let f = {
      search: ''
    }
    filter(f)
  }

  const filter = (p = {}) => {
    setdatafilters({ ...datafilters, ...p })
    GetData(p);
  }

  const sorting = (key) => {
    let sorder = "asc";
    if (datafilters.key == key) {
      if (datafilters.sorder == "asc") {
        sorder = "desc";
      } else {
        sorder = "asc";
      }
    }

    let sortBy = `${key} ${sorder}`;
    filter({ sortBy, key, sorder });
  };

  return (
    <Layout>
      <div className="flex items-center justify-between mb-6">
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-semibold text-[#111827]">
            Supplier Request Data
          </h4>
          <p className="text-sm font-normal text-[#75757A]">
            Here you can see all about your Customer Data
          </p>
        </div>

        <div className="flex gap-3">
          {/* <button
            type="button"
            onClick={e => exportfun(e)}
            className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
            <PiFileCsv className="text-typo text-xl" />
            Export CSV
          </button> */}
          {/* <button
            type="button"
            className="!px-3 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 !bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
            <FiPlus className="text-xl text-white" />
            Add New customer
          </button> */}
        </div>
      </div>




      <div className="bg-white shadow-box border !border-grey  rounded-lg w-full">
        <div className="flex items-start gap-3 p-6">
          <div className="flex items-center gap-2">
            <form className="relative shadow-box !border-grey border bg-white min-w-[320px] rounded-full h-10 flex items-center gap-2 overflow-hidden px-2" onSubmit={HandleSubmit}>
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
              <input
                className="w-full h-full outline-none rounded-full ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                placeholder="Search"
                value={datafilters.search}
                onChange={e => setdatafilters({ ...datafilters, search: e.target.value })}
              />
            </form>
            <button type="submit" className="!bg-primary border !border-primary h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full" onClick={e => HandleSubmit(e)}>
              Search
            </button>
            {datafilters.search ? <>
              <button type="button" onClick={clear} className="!bg-primary border !border-primary flex items-center gap-1 h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full">
                <MdClear className="text-xl text-white" />
                Clear
              </button>
            </> : <></>}
          </div>
        </div>
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="border-y border-[#EAECF0]">
              <tr className=" border-y border-[#EAECF0]">
                <th
                  onClick={() => sorting('supplier_name')}
                  scope="col"
                  className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3">
                  Supplier Name
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                <th
                  scope="col"
                  className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3">
                  Commission Type
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                <th
                  onClick={() => sorting('commissionRate')}
                  scope="col"
                  className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3">
                  Commission Rate
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                  Status
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-r-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Data.map((item, index) => (<tr>
                <td className=" text-typo !px-5 text-sm font-normal !border-l-0 !py-3 !border text-center border-[#EAECF0]">
                  <div className="flex items-center cursor-pointer gap-3.5">
                    <div className="text-left" onClick={() => history(`/request/detail/${item?.id || item?._id}`)}>
                      <span className="block text-left">{item?.supplier_name}</span>
                    </div>
                  </div>
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !py-3 !border text-center border-[#EAECF0]">
                  {item.commission_type || "--"}
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !py-3 !border text-center border-[#EAECF0]">
                  {item.commissionRate || "--"}
                </td>
                <td className=" text-typo !px-5 text-sm font-normal  !py-3 !border text-center border-[#EAECF0]">
                  {item && item.request_status == "pending" ? <><p className='rounded-full bg-yellow-400 px-2 text-xs text-black py-1 inline-flex'>Pending</p></> : item && item.request_status == "confirmed" ? <><p className='rounded-full bg-green-600 px-2 text-xs text-white py-1 inline-flex'>Confirmed</p></> : <><p className='rounded-full bg-red-600 px-2 text-sm text-white py-1 inline-flex'>Cancelled</p></>}
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !border-r-0 !py-3 !border text-center border-[#EAECF0]">
                  <div className='flex justify-center gap-1 items-center'>
                    {item.request_status == "pending" ? <>

                      <button onClick={() => {
                        statusVerified("confirmed", item?._id || item?.id)
                      }} className="border border-[#EBEBEB] text-white rounded-lg bg-green-600 hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl ">
                        <IoMdCheckmark />
                      </button>

                      <button onClick={() => statusVerified("cancelled", item?._id || item?.id)} className="border border-[#EBEBEB] rounded-lg bg-red-600  text-white hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl">
                        <IoMdClose />
                      </button>

                    </> :
                      item.request_status == "cancelled" ? <span>Rejected</span> :
                        <span>Accepted</span>
                    }
                     {item.request_status == "confirmed" && <button
                      title="Sent Message"
                      onClick={()=>copyToClipboard(`${item?.website}?affiliate=${user?.id || user?._id}`)}
                      className="bg-[#FAFAFA]  h-[30px] text-sm cursor-pointer hover:!bg-primary hover:!border-primary transition-all duration-300 hover:text-white flex items-center !px-2 !py-1 !gap-1 border border-[#EBEBEB] !rounded-large text-[#000]">
                      <IoMdCopy />
                    </button>}
                  </div>
                </td>
              </tr>))}
            </tbody>
          </table>
        </div>
        {!Total ? (
          <div className="py-3 text-center">No Data</div>
        ) : (
          <></>
        )}
        {Total > datafilters?.count && (
          <div className="paginationWrapper flex items-center justify-between p-6">
            <div className="table_rowings w-96">
              <p className="text-gray-600 text-sm"> {PaginationTextView(datafilters.page, datafilters.count, Total)}</p>
            </div>
            <Pagination
              currentPage={datafilters.page}
              totalSize={Total}
              sizePerPage={datafilters.count}
              changeCurrentPage={e => {
                setdatafilters({ ...datafilters, page: e });
                GetData({ page: e });
              }}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default RequestData;
