/*
 * @file: user.js
 * @description: Reducers and actions for store/manipulate user's  data
 * @date: 28.11.2019
 * @author: ANil
 */

/******** Reducers ********/

const initialState = {
    url:'https://app2.dazhboards.com/assets/img/logo.webp'
  };
  
  export default function reducer(state = initialState, action) {
    switch (action.type) {
      case 'Logo':
        return action.data
  
      default:
    return state
    }
  }
  