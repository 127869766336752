import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import loader from "../../methods/loader";
import environment from "../../environment";
import axios from "axios";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import Pagination from 'react-pagination-js'
import { MdClear } from "react-icons/md";
import PaginationTextView from "../../components/common/PaginationTextView";
import SelectDropdown from "../../components/common/SelectDropdown";
import Layout from "../../components/global/layout";
import { VscSend } from "react-icons/vsc";
import { Editor } from "@tinymce/tinymce-react";
import tinymcModel from "../../models/tinymc.model";
import Modal from "../../components/common/Modal";

const AffiliateMessages = () => {
  const user = useSelector(state => state.user);
  const [Total, setTotal] = useState(0);
  const history = useNavigate();
  const [Data, setData] = useState([]);
  const [datafilters, setdatafilters] = useState({ page: 1, count: 50, search: "" , type:''})
  const [ShowConfirmModal, setShowConfirmModal] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [receiverId, setRecieverId] = useState('');
  const dataRef = useRef([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    loader(true)
    const payload = {
      subject: subject,
      message: message,
      receiver: receiverId
    };

    try {
      ApiClient.post(`api/affiliate/message`, payload).then(res => {
        if (res.success) {
          setSubject('')
          setMessage('')
          setShowConfirmModal(false)
          swal({
            icon: 'success',
            className: 'smallmodal',
            title: 'Message Sent Successfully !',

          })
        }
        loader(false);
      })
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const DeleteMessage = (id) => {
    if (id) {
      loader(true);
      const payload = {
        "id": id
      }
      ApiClient.delete(`api/affilate/messege`, payload).then(res => {
        if (res.success) {
          GetData()
        }
        loader(false);
      })
    }
  };

  const GetData = (p = {}) => {
    const newfilters = { ...datafilters, ...p }
    loader(true);
    ApiClient.get(`api/affilate/messeges`, newfilters, "", "", { apiCall: true }).then(res => {
      if (res.success) {
        setTotal(res.total)
        setData(res?.data)
        dataRef.current = res?.data
      }
      loader(false);
    })
  }

  const handleChannelSelection = (e) => {
    setdatafilters({ ...datafilters, channels: e })
    let data = {
      channels: e.toString()
    }
    GetData(data)
  }

  useEffect(() => {
    GetData()
  }, [])

  const HandleSubmit = (e) => {
    e.preventDefault();
    GetData();
  }


  const exportfun = async (e) => {
    e.preventDefault()
    const token = await localStorage.getItem("token");
    let newfilters = { ...datafilters, export_to_xls: 'Y' }
    if (newfilters.email) newfilters.email = newfilters.email.toString()
    const req = await axios({
      method: "get",
      url: `${environment.api}api/customer/bookings-stats`,
      responseType: "blob",
      params: newfilters,
      body: { token: token }
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `customerdata.xlsx`;
    link.click();
  }

  const clear = () => {
    let f = {
      search: '',
      affiliate_types: '',
      channels: ''
    }
    filter(f)
  }

  const filter = (p = {}) => {
    setdatafilters({ ...datafilters, ...p })
    GetData(p);
  }

  const sorting = (key) => {
    let sorder = "asc";
    if (datafilters.key == key) {
      if (datafilters.sorder == "asc") {
        sorder = "desc";
      } else {
        sorder = "asc";
      }
    }

    let sortBy = `${key} ${sorder}`;
    filter({ sortBy, key, sorder });
  };

  return (
    <Layout>
      <div className="flex items-center justify-between mb-6">
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-semibold text-[#111827]">
            Supplier Messages
          </h4>
          <p className="text-sm font-normal text-[#75757A]">
            Here you can see all messages of Suppliers 
          </p>
        </div>

        <div className="flex gap-3">
          {/* <button
            type="button"
            onClick={e => exportfun(e)}
            className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
            <PiFileCsv className="text-typo text-xl" />
            Export CSV
          </button> */}
          {/* <button
            type="button"
            className="!px-3 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 !bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
            <FiPlus className="text-xl text-white" />
            Add New customer
          </button> */}
        </div>
      </div>




      <div className="bg-white border !border-grey shadow-box rounded-lg w-full">
        <div className="flex items-start justify-between gap-3 p-6">
          <div className="flex items-center gap-2">
            <form className="relative shadow-box border !border-grey bg-white min-w-[320px] rounded-full h-10 flex items-center gap-2 overflow-hidden px-2" onSubmit={HandleSubmit}>
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
              <input
                className="w-full h-full outline-none ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                placeholder="Search"
                value={datafilters.search}
                onChange={e => setdatafilters({ ...datafilters, search: e.target.value })}
              />
            </form>
            <button type="submit" className="!bg-primary border !border-primary h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full" onClick={e => HandleSubmit(e)}>
              Search
            </button>

            {(datafilters.search) ? <>
              <button type="button" onClick={clear} className="!bg-primary border !border-primary flex items-center gap-1 h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full">
                <MdClear className="text-xl text-white" />
                Clear
              </button>
            </> : <></>}
          </div>

          <div className=''>
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder='Message Type'
                intialValue={datafilters.type}
                theme='search'
                result={(e) =>
                  filter({type: e.value })
                }
                options={[{id:'sent',name:'Sent'},{id:'',name:'Inbox'}]}
              />
            </div>
        </div>
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="border-y border-[#EAECF0]">
              <tr className=" border-y border-[#EAECF0]">
                <th
                  scope="col"
                  className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3">
                  Sender
                </th>
                <th
                  onClick={() => sorting('subject')}
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                  {" "}
                  Subject
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                <th
                  onClick={() => sorting('message')}
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                  Message
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                {/* <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-right bg-[#F7FAFF] !py-3">
                  Request Status
                </th> */}
                <th
                  scope="col"
                  className="text-[#82838B] !border-r-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {dataRef?.current?.map((item, index) => (<tr>
                <td className=" text-typo !px-5 text-sm font-normal !border-l-0 !py-3 !border text-center border-[#EAECF0]">
                  <div className="flex items-center cursor-pointer gap-3.5" onClick={()=>history(`/messages/${item?.id || item?._id}`)}>
                    {/* <img
                      src={methodModel.userImg(item?.image)}
                      height="32px"
                      width="32px"
                      className="object-cover shrink-0 w-8 h-8 shadow-xs rounded-full"
                    /> */}
                    <div className="text-left">
                      <span className="block text-left">{datafilters?.type=='' ? item?.senderDetail?.fullname : item?.receiverDetail?.fullname}</span>
                      <span className="text-[#6B7280] font-normal text-sm block">
                        {datafilters?.type=='' ? item?.senderDetail?.email : item?.receiverDetail?.email}
                      </span>
                    </div>
                  </div>
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !py-3 !border text-center border-[#EAECF0]">
                  {item.subject || "--"}
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !py-3 !border text-center border-[#EAECF0]">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.message,
                    }}
                    className="texteditor "></p>
                </td>
                {/* <td className=" text-typo !px-5 text-sm font-normal !py-3 !border text-right border-[#EAECF0] capitalize">
                 
                  {item.request_status || "--"}
                </td> */}
                <td className=" text-typo !px-5 text-sm font-normal !border-r-0 !py-3 !border text-center border-[#EAECF0]">
                  <div className="flex justify-center items-center gap-2">
                <button
                  onClick={() => DeleteMessage(item?.id || item?._id)}
                  className="bg-[#FAFAFA]   text-sm cursor-pointer hover:!bg-primary hover:!border-primary transition-all duration-300 hover:text-white flex items-center !px-2 !py-1 !gap-1 border border-[#EBEBEB] !rounded-full text-[#000]">
                  Delete
                </button>
               {!datafilters?.type && <button title="Sent Message"
                  onClick={() => { setShowConfirmModal(true); setRecieverId(item?.senderDetail?.sender_id) }}
                  className="bg-[#FAFAFA]  h-[30px] text-sm cursor-pointer hover:!bg-primary hover:!border-primary transition-all duration-300 hover:text-white flex items-center !px-2 !py-1 !gap-1 border border-[#EBEBEB] !rounded-large text-[#000]">
                <VscSend />

                </button>}
                  </div>
                </td>
              </tr>))}
            </tbody>
          </table>
        </div>
        {!Total ? (
          <div className="py-3 text-center">No Data</div>
        ) : (
          <></>
        )}
        {Total > datafilters?.count && (
          <div className="paginationWrapper flex items-center justify-between p-6">
            <div className="table_rowings w-96">
              <p className="text-gray-600 text-sm"> {PaginationTextView(datafilters.page, datafilters.count, Total)}</p>
            </div>
            <Pagination
              currentPage={datafilters.page}
              totalSize={Total}
              sizePerPage={datafilters.count}
              changeCurrentPage={e => {
                setdatafilters({ ...datafilters, page: e });
                GetData({ page: e });
              }}
            />
          </div>
        )}
      </div>
      <Modal
        isHeader={false}
        isFooter={false}
        isOpen={ShowConfirmModal}
        // result={modalResult}
        content={<>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="subject" className="mb-2 inline-flex">Subject:</label>
              <input
                type="text"
                className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message">Message:</label>
              <textarea
                value={message}
                rows={5}
                onChange={(e) =>{ setMessage(e.target.value)}}
                className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-30 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                required
              ></textarea>
            </div>
            <div className="flex justify-end gap-3 items-center">
            <a className="text-sm px-4 py-2 bg-gray-100 text-black font-medium mt-3 rounded-large cursor-pointer" onClick={() => setShowConfirmModal(false)}>Cancel</a>
            <button type="submit" className="text-sm px-4 py-2 bg-primary text-white font-medium mt-3 rounded-large">Sent Message</button>
            </div>
          </form>
        </>}
      />
    </Layout>
  );
};

export default AffiliateMessages;
