import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
// import './style.scss';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import Pagination from 'react-pagination-js'
import Breadcrumb from "../../../components/common/Breadcrumb";

const RequestDetail = (p) => {
  const history = useNavigate()
  const user = useSelector(state => state.user)
  const [filters, setFilter] = useState({ page: 1, count: 50, search: "" });
  const { id, userId } = useParams()
  const [data, setData] = useState()
  const [products, setProducts] = useState([])
  const [productLoader, setProductLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const getDetail = (did) => {
    loader(true)
    ApiClient.get(`api/request/detail`, { id: did }).then(res => {
      if (res.success) {
        setData(res.data)
        getProducts({ addedBy: res?.data?.addedBy?._id })
      }
      loader(false)
    })
  };

  const back = () => {
    history(-1)
  }

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getProducts(p);
  };

  const pageChange = (e) => {
    filter({ page: e });
  };

  const getProducts = (p = {}) => {
    let f = {
      ...filters,
      ...p,
      addedBy: data?.addedBy?._id || data?.addedBy?.id
    };
    setProductLoader(true);
    ApiClient.get("api/products/listing", f).then((res) => {
      if (res.success) {
        setProducts(res.data?.map((item) => ({ ...item, id: item?._id || item?.id })));
        setTotal(res.total);
      }
      setProductLoader(false);
    });
  };

  useEffect(() => {
    getDetail(userId ? userId : id)
  }, [id, userId])

  return (
    <Layout>
      <Breadcrumb
        links={[
          {
            name: "Request Data",
            link: "/request",
          },
        ]}
        currentPage={"Request Detail"}
      />
      <div className="flex items-center">
        {/* <div>
                    <a to="/users" onClick={back}>  <i className="fa text-black fa-arrow-left mr-4 mb-3 " title='Back' aria-hidden="true"></i>dfd</a>
                </div> */}
        <h3 className="text-2xl font-semibold text-[#111827] mb-6 ">
          Request Detail
        </h3>
      </div>
      <div className="max-w-[900px] bg-white shadow-box border !border-grey rounded-large">
        <div className=" ">


          <div className="">
            <div className='p-6'>


              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">Name:</label>
                <div className='profiledetailscls'>{data && data?.addedBy?.fullName}</div>
              </div>
              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">All Categories:</label>
                <div className='profiledetailscls'>{data && data?.allCategories ? <><p className='rounded-full bg-gray-100 px-4 py-1'>Yes</p></> : <><p className='rounded-full bg-red-600 px-4 text-sm text-white py-1'>No</p></>}</div>
              </div>
              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">All Products:</label>
                <div className='profiledetailscls'>{data && data?.allProducts ? <><p className='rounded-full bg-gray-100 px-4 py-1'>Yes</p></> : <><p className='rounded-full bg-red-600 px-4 text-sm text-white py-1'>No</p></>}</div>
              </div>
              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">Apply Rate For All:</label>
                <div className='profiledetailscls'>{data && data?.applyRateForAll ? <><p className='rounded-full bg-gray-100 px-4 py-1'>Yes</p></> : <><p className='rounded-full bg-red-600 px-4 text-sm text-white py-1'>No</p></>}</div>
              </div>
              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">Apply Rate For All Products:</label>
                <div className='profiledetailscls'>{data && data?.allProducts ? <><p className='rounded-full bg-gray-100 px-4 py-1'>Yes</p></> : <><p className='rounded-full bg-red-600 px-4 text-sm text-white py-1'>No</p></>}</div>
              </div>
              <div className="flex justify-between mb-3">
                <label className="text-typo mb-2 text-base font-medium">Request Status</label>
                <div className='profiledetailscls'>{data && data.request_status == "pending" ? <><p className='rounded-full bg-yellow-400 px-4 text-sm text-black py-1'>Pending</p></> : data && data.request_status == "confirmed" ? <><p className='rounded-full bg-green-600 px-4 text-sm text-white py-1'>Confirmed</p></> : <><p className='rounded-full bg-red-600 px-4 text-sm text-white py-1'>Cancelled</p></>}</div>
              </div>
              <div className="flex justify-between">
                <label className="text-typo mb-2 text-base font-medium">Commission Type</label>
                <div className='profiledetailscls capitalize'>{data && data.commission_type}</div>
              </div>
            </div>
            <div className="col-span-12">


              <div className=" bg-[#FAFBFC] rounded-large">
                {false ?
                  (
                    <>

                    </>
                  ) : (
                    <>
                      {data?.products?.length != 0 && <div className="scrollbar w-full overflow-auto">
                        <table className="w-full">
                          <thead className='border-y border-[#EAECF0]'>


                            <tr className=' border-y border-[#EAECF0]'>
                              <th className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3'>Product Name</th>
                              <th className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3'>Commission Rate</th>
                              <th className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#F7FAFF] !py-3'>Type of Amount</th>
                            </tr>
                          </thead>
                          <tbody>


                            {data?.products.map((itm) => {
                              return (
                                <tr>
                                  <td
                                    className=" text-typo !px-5 text-sm font-normal !border-l-0 !py-3 !border text-left border-[#EAECF0]">
                                    <p className="text-xs font-normal text-[#101828] mb-0 capitalize">
                                      {itm?.productId?.name}
                                    </p>
                                  </td>

                                  <td
                                    className=" text-typo !px-5 text-sm font-normal !border-l-0 !py-3 !border text-end border-[#EAECF0]">

                                    <p className="text-xs font-normal text-[#101828] mb-0 capitalize">
                                      {itm?.commissionRate}
                                    </p>

                                  </td>

                                  <td
                                    className=" text-typo !px-5 text-sm font-normal !border-l-0 !py-3 !border text-end border-[#EAECF0]">

                                    <p className="text-xs font-normal text-[#101828] mb-0 capitalize">
                                      {itm?.amountOrPer}
                                    </p>

                                  </td>

                                </tr>
                              );
                            })}

                          </tbody>
                        </table>
                      </div>}
                    </>
                  )}
              </div>
            </div>

            {
              (data?.allProducts || data?.applyRateForAll) ? (
                <div className="col-span-12 p-4 !pt-0">
                  <hr className="h-[1px] bg-[#EAECF0] !my-5" />
                  <label className="text-typo block text-base font-medium mb-4">Products:</label>

                  <form
                    className="flex whitespace-nowrap gap-2.5 flex-wrap !mb-4 justify-end"
                    onSubmit={(e) => {
                      e.preventDefault();
                      filter();
                    }}>
                    <input
                      type="text"
                      className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-4 !ring-primary !outline-primary disabled:!bg-gray-200"
                      placeholder="Search by Product Name"
                      value={filters.search}
                      onChange={(e) =>
                        filter({ search: e.target.value, page: 1 })
                      }
                    />
                    {/* {/ <button className="btn btn-primary" type="button" onClick={e=>filter()}>Search</button> /} */}
                  </form>

                  <div className="p-3 bg-[#FAFBFC] rounded-large">
                    {productLoader ? (
                      <>
                        {/* <div className="text-center">Loading... <i className="fa fa-spin fa-spinner"></i></div> */}
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                        <div
                          className="shine mb-2"
                          style={{ height: "50px" }}></div>
                      </>
                    ) : (
                      <>
                        {total == 0 ? (
                          <>
                            <div className="text-center">
                              No Products
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="w-full">
                          <table className="w-full">
                            {products.map((itm) => {
                              return (
                                <tr>
                                  <td
                                    className="!p-2">
                                    <div className="block lg:flex items-center !gap-3">
                                      <img
                                        src={methodModel.noImg(
                                          itm?.images?.length
                                            ? itm?.images[0]
                                              .thumbnailUrl
                                            : ""
                                        )}
                                        className="w-6 h-6 lg:w-9 lg:h-9 mb-2 shrink-0 rounded-large"
                                      />
                                      <p className="text-xs font-normal text-[#101828] mb-0 capitalize">
                                        {itm.name}
                                      </p>
                                    </div>
                                  </td>
                                  <td
                                    className="!p-2">
                                    <p className="text-sm font-medium text-[#101828] mb-0 capitalize">
                                      {itm.categoryName || "--"}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>

                        {total > filters.count ? (
                          <div className="paginationWrapper">
                            <span>
                              Show {filters.count} from {total}{" "}
                              Products
                            </span>
                            <Pagination
                              currentPage={filters.page}
                              totalSize={total}
                              sizePerPage={filters.count}
                              changeCurrentPage={pageChange}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}

          </div>
        </div>
      </div>
    </Layout >
  );
};

export default RequestDetail;
