import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { Disclosure, Transition } from "@headlessui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { TbChartBar } from "react-icons/tb";
import { PiHandCoins, PiHandbagBold } from "react-icons/pi";
import { FiCloud, FiCloudOff, FiPackage, FiSettings, FiThumbsUp, FiUsers } from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { HiOutlineInbox } from "react-icons/hi2";
import { RiCoupon2Fill } from "react-icons/ri";

import styles from "./index.module.css";
import {
  IoFileTrayFullOutline,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import environment from "../../../environment";
import { useEffect, useState } from "react";
import ApiClient from "../../../methods/api/apiClient";
import { login_success } from "../../../actions/user";
import ChatbotIcon from "../../../assets/chatbot-icon";
import { LuFolderSymlink } from "react-icons/lu";

const Html = ({ ListItemLink, tabclass, urlAllow, route, isOpen, scrollToId }) => {
  const location = useLocation();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const ValidationNumber = (user?.supplier_id?.id || user?.subRole?.id == environment.SubRolePartner || user?.subRole?._id == environment.SubRolePartner) ? 0 : 1
  const navigate = useNavigate();
  const dataConnectionLink = [
    "/pos",
    "/api/review-connected",
    "/api/review",
    "/marketing-analytics",
    "/api/accounting",
    "/marketing-software",
  ];
  const dataConnection = dataConnectionLink.includes(location.pathname);
  useEffect(() => {
    ApiClient.get(`api/user/profile`, { id: user?.id || user?._id }, "", "", {
      apiCall: true,
    }).then((res) => {
      if (res.success) {
        setData(res.data);
        const data = res?.data;
        if (data?.subRole && data?.subRole?._id) {
          data["subRole"] = { ...data?.subRole, id: data?.subRole?._id };
        }
        dispatch(login_success(data));
      }
    });
  }, []);

  return (
    <>
      <div className={`px-[8px] ${isOpen && styles.sm_sidebar}`}>
        <h6
          className={`${isOpen ? "py-[12px] text-center" : "p-[12px]"
            } text-xs font-medium text-[#7E8B99] mt-[12px]`}>
          <span className="sidebar_text"> MAIN MENU </span>
        </h6>
        <>
          <>
            <ul className="space-y-2">
              {urlAllow("readDashboard") && (
                <li >
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#00b884] hover:bg-[#00b884]/10 !no-underline transition-all " +
                      (isActive &&
                        " !text-[#00b884] !bg-[#EDECF9] !font-medium")
                    }>
                    <RiHome6Line className="text-[#00b884] shrink-0 text-lg" />
                    <span className="text-inherit sidebar_text leading-none">
                      Dashboard
                    </span>
                  </NavLink>
                </li>
              )}
              <li className=''>
                <Disclosure as="div" defaultOpen={tabclass("affiliates")}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 transition-all" >
                        <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                          <LuFolderSymlink className="text-[#7b68ee] shrink-0 text-lg" />
                          <span className="sidebar_text">
                            {!user.sidebar ? "Affiliate Section" : <>Comm<br />Channel</>}
                          </span>
                        </span>
                        <TiArrowSortedDown
                          className={`${open ? "" : "-rotate-90 transform"
                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                        />
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-300 ease-in-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-300 opacity-300"
                        leave="transition duration-300 ease-in-out"
                        leaveFrom="transform scale-300 opacity-300"
                        leaveTo="transform scale-95 opacity-0">
                        <Disclosure.Panel
                          className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                          <ul className="space-y-2">

                          {user?.role?.name == "Affiliate" && <li
                            //  className="pointer-events-none"
                            >
                              <NavLink
                                to="/suppliers"
                                className={({ isActive }) =>
                                  "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                  (isActive && " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                }>
                                {/* <ChatbotIcon classes="text-[#705EE6]" /> */}
                                <span className="text-inherit  sidebar_text leading-none">
                                  {" "}
                                  Suppliers
                                  {/* <span className="ml-1 bg-[#FFD6B2] inline-flex whitespace-nowrap text-center py-[3px] px-2 !rounded text-[10px] text-[#3C3E49]">Coming Soon</span> */}
                                </span>
                              </NavLink>
                            </li>}

                            {user?.role?.name == "Affiliate" && <li
                            //  className="pointer-events-none"
                            >
                              <NavLink
                                to="/request"
                                className={({ isActive }) =>
                                  "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                  (isActive && " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                }>
                                {/* <ChatbotIcon classes="text-[#705EE6]" /> */}
                                <span className="text-inherit  sidebar_text leading-none">
                                  {" "}
                                  Supplier Request
                                  {/* <span className="ml-1 bg-[#FFD6B2] inline-flex whitespace-nowrap text-center py-[3px] px-2 !rounded text-[10px] text-[#3C3E49]">Coming Soon</span> */}
                                </span>
                              </NavLink>
                            </li>} {user?.role?.name == "Affiliate" && <li
                            //  className="pointer-events-none"
                            >
                              <NavLink
                                to="/messages"
                                className={({ isActive }) =>
                                  "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                  (isActive && " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                }>
                                {/* <ChatbotIcon classes="text-[#705EE6]" /> */}
                                <span className="text-inherit  sidebar_text leading-none">
                                  {" "}
                                  Messages
                                  {/* <span className="ml-1 bg-[#FFD6B2] inline-flex whitespace-nowrap text-center py-[3px] px-2 !rounded text-[10px] text-[#3C3E49]">Coming Soon</span> */}
                                </span>
                              </NavLink>
                            </li>}
                            {user?.role?.name == "Affiliate" && <li
                            //  className="pointer-events-none"
                            >
                              <NavLink
                                to="/trackData"
                                className={({ isActive }) =>
                                  "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                  (isActive && " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                }>
                                {/* <ChatbotIcon classes="text-[#705EE6]" /> */}
                                <span className="text-inherit  sidebar_text leading-none">
                                  {" "}
                                  Track Data
                                  {/* <span className="ml-1 bg-[#FFD6B2] inline-flex whitespace-nowrap text-center py-[3px] px-2 !rounded text-[10px] text-[#3C3E49]">Coming Soon</span> */}
                                </span>
                              </NavLink>
                            </li>}

                          </ul>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </li>
            </ul>
          </>
        </>
      </div>
    </>
  );
};

export default Html;
