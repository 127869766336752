import { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import methodModel from "../../../methods/methods";
import { FaArrowUp } from "react-icons/fa";
import environment from "../../../environment";
import swal from "sweetalert";
import socket from "../../../models/socket.model";

const Layout = ({ children , classes = "" }) => {
  const WebDetails=useSelector(state=>state.logo);
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const history = useNavigate();
  const URLPath = window.location.pathname;
  const [showIcon, setshowIcon] = useState(false);

  const useAnalyticsEventTracker = (category = "Blog category") => {
    const eventTracker = (action = "test action", label = "test label") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  ReactGA.initialize("G-PDR2K98N27");
  useEffect(() => {
    useAnalyticsEventTracker("Dazboard");
  }, []);

  useEffect(() => {
    if (!user?.loggedIn || !localStorage.getItem('token')) {
      history("/login");
    } else {
      if (window.location.pathname == '/') {
        if (!user.companyName) {
          history()
        } else {
          history('/dashboard')
        }
      }
    }
  }, [searchState, user]);

  const logo = () => {
    let value = WebDetails?.logo
    return value;
  };
  const logos = () => {
    let value = "/assets/img/logo-short.webp";
    return value;
  };
  const router = () => {
    let route = localStorage.getItem("route");
    history(route);
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  window.onscroll = function () {
    if (document.documentElement.scrollTop <= 50) {
      setshowIcon(false);
    } else {
      setshowIcon(true);
    }
  };




  const ParnterNavigateHandler = () => {
    if (user?.subRole?.id == environment.SubRolePartner) {
      if (user?.subscriptionId || user?.on_trial || user?.supplier_id) {

      } else {
        if (URLPath == '/review-dashboard' || URLPath == '/reviews' || URLPath == '/reseller-dashboard' || URLPath == '/reseller-data' || URLPath == '/crm' || URLPath == '/waiver' || URLPath == '/accounting/dashboard' || URLPath == '/accounting/transactions' || URLPath == '/accounting/invoices' || URLPath == '/omni-channels/live-chat' || URLPath == '/plans' || URLPath == '/activeplan'
          || URLPath == '/marketing/google-analytics' || URLPath == '/users' || URLPath == '/card' || URLPath == '/pos' || URLPath == '/coupons' || URLPath == '/coupons-data') {
          history(-1)
          swal({ text: "If you want to use this email as Supplier account. Ask your admin to make it disable or you can signup with new email as supplier", icon: "error" });

        }
      }
    }
  }
  useEffect(() => {
    ParnterNavigateHandler()
  }, [URLPath])

  useEffect(() => {

    

  }, [URLPath])



  return (
    <>
      <div component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        <Header />
        <div
          className={`main-wrapper flex `}>
          <div className={`main-sidebar ${user?.sidebar ? "active-sidebar" : ""
            } scrollbar transition-[width] duration-500`}>
            <div
              className="sidebar-brand p-3 pt-4 pl-5 text-left !bg-transparent"
              style={{ marginTop: "5px", height: "50px", marginBottom: "2px" }}>
              <label className="editLogo">
                <img src={logo()} className="logocls show-logo" width="250" height="35" />
                <img src={logos()} className="hide-logo" width="24" height="24" />
              </label>
            </div>
            {user?.logo ? (
              <div
                className="flex justify-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <img
                  src={methodModel.userImg(user?.logo || "")}
                  alt="photo"
                  width="40"
                  height="40"
                  style={{
                    width: "40px",
                    marginBottom: "2px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              </div>
            ) : null}
            <Sidebar />
          </div>
          <main className="main scrollbar">
            <div className={`!bg-[#F8F9FB] !p-5 mt-[69px] ${classes}`}>{children}</div>
          </main>
        </div>
        {URLPath == "/sales" ||
          URLPath == "/dashboard" ||
          URLPath == "/review-dashboard" ||
          URLPath == "/product-dashboard" ||
          URLPath == "/customers" ||
          URLPath == "/marketing/google-analytics" ||
          URLPath == "/accounting/dashboard" ? (
          <div className={`z-[999] h-10 w-10 fixed bottom-16 right-16 cursor-pointer shadow-box border !border-grey bg-white flex items-center justify-center d-${showIcon ? "block" : "none"}`}>
            <FaArrowUp className={`d-${showIcon ? "block" : "none"}`}
              onClick={(e) => scrollToTop()}
              id="arrow-up"
              title="Back Top"
            />
          </div>
        ) : null}
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
export default Layout;
