import React from "react";
import ReactDOM from "react-dom";
import Main from "./main";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import configureStore from "./config";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const { persistor, store } = configureStore(history);

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
      <PersistGate loading={"loading..."} persistor={persistor}>
         <Main/>
        {/* </ConnectedRouter> */}
      </PersistGate>
  </Provider>
);
